import 'modernizr';
import bowser from 'bowser';

let oldIE = bowser.msie && bowser.version <= 10,
	oldChromeDesktop = bowser.blink && !bowser.mobile && bowser.version <= 46,
	oldFirefoxDesktop = bowser.firefox && !bowser.mobile && bowser.version <= 43,
	safariMobile = bowser.ios && bowser.safari && bowser.version < 9;


window.bowser 	= bowser;
let html 		= window.document.getElementsByTagName('html')[0];
let bowserClass = window.bowser.name.toLowerCase();

if (oldIE || oldFirefoxDesktop || oldChromeDesktop || safariMobile ) {
	html.classList.add('browser-is-not-supported');
} else {
	html.className += ' ' + bowserClass;
}